import React, { useMemo } from 'react'
import ReactFlow, {
  Controls,
  Background,
} from 'react-flow-renderer'
import CustomBezierEdge from './CustomBezierEdge.js'
import EntityNode from './EntityNode.js'
import MarkerDefinition from './MarkerDefinition.js'

// source: https://reactflow.dev/docs/examples/overview/

const OverviewFlow = (props) => {
  const { rfInstructions } = props
  const { nodes, edges } = rfInstructions

  // to add other types =? add entry to the object ?
  const nodeTypes = useMemo(() => ({ entityNode: EntityNode }), [])
  const edgeTypes = useMemo(() => ({ custom: CustomBezierEdge }), [])

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      snapToGrid={true}
      snapGrid={[15, 15]}
      fitView
    >
      <MarkerDefinition id='edge-marker-red' color='red' />

      <Controls showInteractive={false} />
      <Background color="#aaa" gap={16} />
    </ReactFlow>
  )
}

export default OverviewFlow;
