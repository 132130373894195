import React from 'react'
import { graphql } from "gatsby"
import OverviewFlow from '../../../reactFlow/OverviewFlow.js'
import { Box } from '@mui/material'
import graphQL2ReactFlow from '../../../reactFlow/graphQL2ReactFlow.js'
import { useTheme } from '@emotion/react'

// @TODO: review the query with the new graphql "virtual inverse properties"

export const query = graphql`
fragment classAndInheritanceRF on Query {
  rdfsClass(id: {eq: $id}) {
    id
    type
    isAbstract
    label {
      _language
      _value
    }
    subClassOf {
      id
      type
      isAbstract
      label {
        _language
        _value
      }
      hasProperties{
				id
        label {
          _language
          _value
        }
        type
        range {
					id
          ... on rdfs_Class {
            label {
              _language
              _value
            }
          }
          ... on rdfs_Datatype {
            label {
              _language
              _value
            }
          }
        }
      }
    }
    hasProperties {
      id
      type
      label {
        _language
        _value
      }
      range {
        id
        ... on rdfs_Class {
          label {
            _language
            _value
          }
        }
        ... on rdfs_Datatype {
          label {
            _language
            _value
          }
        }
      }
    }
    isRangedBy {
      id
      label {
        _language
        _value
      }
      domain {
        id
        label {
          _language
          _value
        }
      }
    }
  }
}
`

// test exemple : http://localhost:1111/nfr/Certification

export default function ClassRFComponent(props) {
  const theme = useTheme()
  const colors = {
    entityColor : theme.palette.primary.main
  }

  // default for testing chart
  // const chart = classDiagramBuilder(props.data)
  const rfInstructions = graphQL2ReactFlow(props.data.rdfsClass, colors)
  // console.log(rfInstructions)
  return (
    <Box
      height={'500px'}
    >
      <OverviewFlow rfInstructions={rfInstructions}/>
    </Box>
  )
}
