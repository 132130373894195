import React from 'react'
import { Handle } from 'react-flow-renderer'

import './index.css'

const handleStyle = {
  // @TODO: restaure it for view handles
  // height: '8px',
  // width: '8px',
  // background: '#555'

  border: 'none',
  background: 'none',
}

export default function EntityNode({ data, isConnectable }){
  return (
    <>
      <div>{data.label}</div>

      {/* Properties connexions for bottom to top relation */}
      <Handle
        type='source'
        position='top'
        id='propertyTopSource'
        style={handleStyle}
        isConnectable={isConnectable}
      />

      <Handle
        type='target'
        position='bottom'
        id='propertyBottomTarget'
        style={handleStyle}
        isConnectable={isConnectable}
      />


      {/* Properties connexion for left to right relation */}
      <Handle
        type='source'
        position='right'
        id='propertyRightSource'
        style={handleStyle}
        isConnectable={isConnectable}
      />

      <Handle
        type='target'
        position='left'
        id='propertyLeftTarget'
        style={handleStyle}
        isConnectable={isConnectable}
      />

      {/* @TODO: Properties connexion for right to left relation */}


    </>
  );
}
