import { Position }  from 'react-flow-renderer'
// extracted from : https://github.com/wbkd/react-flow/blob/41db69e06c22d278657ba810198f371119b4972b/src/components/Edges/utils.ts
// redefine the "getCenter", but use svg's textAnchors verbs : start, middle, end


const LeftOrRight = [Position.Left, Position.Right];

export default function getStart({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition = Position.Bottom,
  targetPosition = Position.Top,
}){
  const sourceIsLeftOrRight = LeftOrRight.includes(sourcePosition);
  const targetIsLeftOrRight = LeftOrRight.includes(targetPosition);

  // we expect flows to be horizontal or vertical (all handles left or right respectively top or bottom)
  // a mixed edge is when one the source is on the left and the target is on the top for example.
  const mixedEdge = (sourceIsLeftOrRight && !targetIsLeftOrRight) || (targetIsLeftOrRight && !sourceIsLeftOrRight);

  if (mixedEdge) {
    console.warn('This is mixedEdge case to check!!')
    const xOffset = sourceIsLeftOrRight ? Math.abs(targetX - sourceX) : 0;
    const centerX = sourceX > targetX ? sourceX - xOffset : sourceX + xOffset;

    const yOffset = sourceIsLeftOrRight ? 0 : Math.abs(targetY - sourceY);
    const centerY = sourceY < targetY ? sourceY + yOffset : sourceY - yOffset;

    return [centerX, centerY, xOffset, yOffset];
  }

  const xOffset = Math.abs(targetX - sourceX) / 2;
  // const centerX = targetX < sourceX ? targetX + xOffset : targetX - xOffset;
  const marginX = 0
  const centerX = targetX < sourceX ? sourceX + marginX : sourceX - marginX

  const yOffset = Math.abs(targetY - sourceY) / 2;
  // const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset;
  const marginY = 15
  const centerY = targetY < sourceY ? sourceY - marginY : sourceY + marginY

  return [centerX, centerY, xOffset, yOffset];
}
