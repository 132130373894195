import { MarkerType } from 'react-flow-renderer'
import chunkArray from '../arrayUtils/chunkArray.js'
import getEntityLabel from '../EntityUtils/getEntityLabel.js'

const yPadding = 100
const xPadding = 200
const xCenterPadding = 400
const rootPosition = { x: 1000, y: 0 }

const baseLines = {
  top: { y: rootPosition.y - yPadding },
  center: {},
  bottom: { y: rootPosition.y + yPadding },
  left: {},
  right: { x: rootPosition.x + xCenterPadding },
  farright: { x: rootPosition.x + xCenterPadding + 400 },
}

const relOrientation = {
  verticalTop: {
    sourceHandle: 'propertyTopSource',
    targetHandle: 'propertyBottomTarget',
  },
  verticalBottom: {},
  horizontalRight: {
    sourceHandle: 'propertyRightSource',
    targetHandle: 'propertyLeftTarget',
  },
  horizontalLeft: {},
}

const subClassRelOptions = {
  type: 'step',
  style: { stroke: '#f6ab6c' },
  animated: true,
}

const rangeRelOptions = {
  style: { stroke: '#f6ab6c' },
}

const currentColors = {
  entityColor: '#9370DB',
  entityColorProp: '#223c87'
}

export default function graphQL2ReactFlow(rdfsClass, colors) {

  Object.assign(currentColors, colors)

  // root
  const diagRootSubject = emitEntityNode(rdfsClass)
  diagRootSubject.position = rootPosition

  // subClassOf
  const diagObject = rdfsClass.subClassOf ?
    rdfsClass.subClassOf.map(entity => {
      return emitEntityNode(entity)
    }
    ) : []

  // subClass positionning
  horizontalDistribute(diagObject, baseLines.top)

  const subClassRel = diagObject.map(diagO => {
    return emitRelation(diagRootSubject, 'subClassOf', diagO, relOrientation.verticalTop, subClassRelOptions)
  }
  )

  // extraction of subClass's properties for RootSubject assignement
  const subClassProperties = rdfsClass.subClassOf ?
    rdfsClass.subClassOf.map(entity => entity.hasProperties).flat()
    : []


  // properties processing
  const directProperties = rdfsClass.hasProperties ? rdfsClass.hasProperties : []
  const allProperties = [...directProperties, ...subClassProperties]
  const rangeObjects = allProperties.map(ap => !ap.range ? [] : ap.range.length ? ap.range : [emitUnknow()]).flat()

  // We put another boxes with properties:
  const diagDomainProp = allProperties.map(ro => emitEntityNode(ro))

  verticalDistribute(diagDomainProp, baseLines.right)
  const domainPropsRel = allProperties.map(
    (elem, i) => {
      return emitRelation(diagRootSubject, '', elem, relOrientation.horizontalRight, rangeRelOptions)
    }
  )

  // duplicates of properties for n(multiples) Types per properties: -> Create an error when loading for now :s but work in graphic:
  const allPropertiesDuplicates = allProperties.map(p => {
    if(!p.range) return []
    return p.range.map(r => p)
  }).flat()

  // reduce the number of boxes for same Types:
  const rangeObjectsReduce = rangeObjects.reduce((acc, v) => {
    const found = acc.find(a => a.id === v.id)
    if (!found) acc.push(v)
    return acc
  }, [])

  const diagDomainPropertiesReduce = rangeObjectsReduce.map(ro => emitEntityNode(ro))

  // get Types on the far right
  const diagDomainProperties = rangeObjects.map(ro => emitEntityNode(ro))

  verticalDistribute(diagDomainPropertiesReduce, baseLines.farright)
  const domainPropertiesRel = allPropertiesDuplicates.map(
    (ap, i) => {
      if (!diagDomainProperties[i]) {
        console.warn('This is a case to check, no domain property that range for', diagRootSubject)
        return {}
      }
      return emitRelation(ap, '', diagDomainProperties[i], relOrientation.horizontalRight, rangeRelOptions)
    }
  )


  // **** RangedBy management
  // prepare the 2 main parallels array to manage relationSource & relationEdge
  const { rangeEntities, rangeRelations } = rdfsClass.isRangedBy ?
    getRE_RR(rdfsClass.isRangedBy) : [[], []]

  const diagRangeSubjects = rangeEntities.map(e => emitEntityNode(e))
  // set positions
  horizontalDistribute(diagRangeSubjects, baseLines.bottom)

  const rangedByRelOptions = {
    type: 'custom',
    ...rangeRelOptions,
  }

  const diagRangeRel = rangeRelations.map((rr, i) => {
    return emitRelation(diagRangeSubjects[i], rr, diagRootSubject, relOrientation.verticalTop, rangedByRelOptions)
  })


  const rfInstructions = {
    nodes: [
      diagRootSubject,
      ...diagObject,
      ...diagRangeSubjects,
      ...diagDomainPropertiesReduce,
      ...diagDomainProp,
    ],
    edges: [
      // no direct root relations,
      ...subClassRel,
    ...diagRangeRel,
      ...domainPropertiesRel,
      ...domainPropsRel,
    ],

  }
  
  return rfInstructions
}

function getRE_RR(isRangedBy) {
  return isRangedBy.reduce((acc, irb) => {
    if (irb.domain) {
      irb.domain.forEach(domain => {
        acc.rangeEntities.push(domain)
        acc.rangeRelations.push(irb)
      })
    }
    return acc
  },
    { rangeEntities: [], rangeRelations: [] }
  )
}

let unknowIndex = 0
function emitUnknow() {
  unknowIndex += 1
  return {
    id: 'hrrdf:Unknow-' + unknowIndex,
    label: ['Unknow-' + unknowIndex],
  }
}

const lineSize = 4
const linePadding = 120
function horizontalDistribute(diagEntities, orientation) {
  const { y } = orientation
  if (!y) throw new Error('No stable axis defined')
  const diagLines = chunkArray(diagEntities, lineSize)

  diagLines.forEach((line, linei) => {
    const lineY = y + (linePadding * linei)

    line.forEach((diagE, relativei) => {
      const x = rootPosition.x - (xPadding * relativei)
      diagE.position = { x, y: lineY }
    })

  })

}

function verticalDistribute(diagEntities, orientation) {
  const { x } = orientation
  if (!x) throw new Error('No stable axis defined')

  // 1/ calculate the y total length
  const yTotalLength = yPadding * diagEntities.length
  // 2/ calculate the starty by distribution of equal sizes around the rootPosition
  const yStart = rootPosition.y - (yTotalLength / 2)

  diagEntities.forEach((diagE, i) => {
    const y = yStart + (yPadding * i)
    diagE.position = { x, y }
  })
}

function emitEntityNode(rdfEntity) {
  const { id } = rdfEntity
  const label = getEntityLabel(rdfEntity)
  let color = (rdfEntity.type !== "rdf_Property") ? currentColors.entityColor : currentColors.entityColorProp
  return {
    id,
    type: 'entityNode',
    data: {
      label,
    },
    style: { border: '4px solid', borderColor: color, padding: 10 },
  }
}

function emitRelation(diagSubject, property, diagObject, orientation, relOptions = {}) {

  const id = `${diagSubject.id}-${diagObject.id}`
  const source = diagSubject.id
  const target = diagObject.id

  // @TODO: check the "property" parameter. This should be improved to not have `string or property.value`
  let label = getEntityLabel(property)

  // isTypeOrPropertyEntity for arrow at the end of link or not:
  // let markerTypeSel = (diagObject.type !== "rdf_Property") ? MarkerType.ArrowClosed : {}
  let markerEnd = (diagObject.type !== 'rdf_Property') ?
    { type: MarkerType.ArrowClosed, color: '#f00' } : {}

  return {
    id,
    source,
    target,
    ...orientation,
    label,
    // arrowHeadType: markerTypeSel,
    markerEnd,
    ...relOptions,
  }

}
