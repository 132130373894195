
// from : https://ourcodeworld.com/articles/read/278/how-to-split-an-array-into-chunks-of-the-same-size-easily-in-javascript
// best perf (and readability) solution

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} Array to split
 * @param chunkSize {Integer} Size of every group
 */
export default function chunkArray(myArray, chunk_size) {
  const localArray = [...myArray]
  const results = [];

  while (localArray.length) {
    results.push(localArray.splice(0, chunk_size));
  }
  console.log(results)
  return results;
}

// Split in group of 3 items
// var result = chunkArray([1,2,3,4,5,6,7,8], 3);
// // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
// console.log(result);
